import React, { useState, useEffect } from 'react';
import { 
  Terminal, 
  Folders, 
  GitBranch, 
  Bug, 
  Settings, 
  User, 
  Code2, 
  Database, 
  Coffee,
  Cpu,
  Network,
  Globe,
  Mail,
  Github,
  Linkedin,
  MessagesSquare,
  CheckCircle2,
  Timer,
  Braces
} from 'lucide-react';

const VSCodePortfolio = () => {
  const [activeSection, setActiveSection] = useState('welcome');
  const [loading, setLoading] = useState(true);
  const [terminalOutput, setTerminalOutput] = useState([]);
  const [input, setInput] = useState('');
  const [sidebarMinimized, setSidebarMinimized] = useState(false);

  useEffect(() => {
    const initSequence = async () => {
      await new Promise(r => setTimeout(r, 1000));
      setLoading(false);
      setTerminalOutput([
        { type: 'info', content: '🚀 Development environment initialized' },
        { type: 'success', content: '✓ All systems operational' },
        { type: 'command', content: 'Imran.Chowdhury.init()' }
      ]);
    };
    initSequence();
  }, []);

  const sections = {
    welcome: {
      icon: Coffee,
      title: 'Welcome',
      content: (
        <div className="space-y-6 animate-fadeIn">
          <div className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500">
            Imran Chowdhury
          </div>
          <div className="text-xl text-slate-300">Backend Engineer</div>
          <div className="prose prose-invert max-w-none">
            <p className="text-slate-400">
              Passionate about building scalable, high-performance systems that drive real impact.
            </p>
          </div>
          <div className="grid lg:grid-cols-2 sm:grid-cols-1 gap-4">
            <div className="bg-slate-800/50 rounded-lg p-4 border border-indigo-500/20 hover:border-indigo-500/40 transition-all group">
              <div className="flex items-center gap-2 text-indigo-400 mb-2">
                <Cpu className="group-hover:text-indigo-300 transition-colors" />
                <span className="font-semibold">Core Skills</span>
              </div>
              <div className="text-slate-300 text-sm">
                Node.js, TypeScript, System Architecture, CI/CD Optimization
              </div>
            </div>
            <div className="bg-slate-800/50 rounded-lg p-4 border border-purple-500/20 hover:border-purple-500/40 transition-all group">
              <div className="flex items-center gap-2 text-purple-400 mb-2">
                <Network className="group-hover:text-purple-300 transition-colors" />
                <span className="font-semibold">Focus Areas</span>
              </div>
              <div className="text-slate-300 text-sm">
                Cloud Platforms (AWS, Cloudflare), Scalable Infrastructure, System Design
              </div>
            </div>
          </div>
        </div>
      )
    },
    
    skills: {
      icon: Code2,
      title: 'Technical Stack',
      content: (
        <div className="space-y-8 animate-fadeIn">
          <div className="space-y-4">
            {[
              {
                category: 'Backend Development',
                color: 'from-indigo-500 to-blue-500',
                skills: [
                  { name: 'Node.js', level: 95 },
                  { name: 'FastAPI', level: 90 },
                  { name: 'Go', level: 80 }
                ]
              },
              {
                category: 'Database & Caching',
                color: 'from-purple-500 to-pink-500',
                skills: [
                  { name: 'MongoDB', level: 92 },
                  { name: 'PostgreSQL', level: 88 },
                  { name: 'Redis', level: 85 },
                  { name: 'Elasticsearch', level: 82 }
                ]
              },
              {
                category: 'DevOps & Cloud',
                color: 'from-rose-500 to-orange-500',
                skills: [
                  { name: 'Docker', level: 90 },
                  { name: 'AWS', level: 88 },
                  { name: 'CI/CD', level: 85 },
                  { name: 'Cloudflare', level: 80 }
                ]
              }
            ].map(({ category, color, skills }) => (
              <div key={category} className="space-y-3">
                <h3 className={`text-lg font-semibold bg-gradient-to-r ${color} bg-clip-text text-transparent`}>
                  {category}
                </h3>
                <div className="grid gap-3">
                  {skills.map(({ name, level }) => (
                    <div key={name} className="space-y-1">
                      <div className="flex justify-between text-sm">
                        <span className="text-slate-300">{name}</span>
                        <span className="text-slate-400">{level}%</span>
                      </div>
                      <div className="h-2 bg-slate-700 rounded-full overflow-hidden">
                        <div 
                          className={`h-full rounded-full bg-gradient-to-r ${color} transition-all duration-1000 ease-out`}
                          style={{ width: `${level}%` }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      )
    },
    // projects: {
    //   icon: Folders,
    //   title: 'Projects',
    //   content: (
    //     <div className="space-y-6 animate-fadeIn">
    //       {[
    //         {
    //           title: 'Enterprise Data Pipeline',
    //           description: 'High-performance ETL system processing 5TB+ daily data with real-time analytics.',
    //           metrics: [
    //             { icon: Timer, text: '40% faster processing' },
    //             { icon: CheckCircle2, text: '99.99% uptime' }
    //           ],
    //           tech: ['Python', 'Apache Kafka', 'AWS', 'Elasticsearch'],
    //           color: 'from-indigo-500 to-blue-500'
    //         },
    //         {
    //           title: 'Microservices Platform',
    //           description: 'Scalable microservices architecture handling 1M+ daily requests with automated deployment.',
    //           metrics: [
    //             { icon: Timer, text: '60% reduced latency' },
    //             { icon: CheckCircle2, text: '95% test coverage' }
    //           ],
    //           tech: ['Java', 'Spring Boot', 'Docker', 'Kubernetes'],
    //           color: 'from-purple-500 to-pink-500'
    //         },
    //         {
    //           title: 'Real-time Analytics Engine',
    //           description: 'Live data processing and visualization system supporting 100K concurrent users.',
    //           metrics: [
    //             { icon: Timer, text: '<100ms latency' },
    //             { icon: CheckCircle2, text: '100K concurrent users' }
    //           ],
    //           tech: ['Node.js', 'Redis', 'WebSocket', 'React'],
    //           color: 'from-rose-500 to-orange-500'
    //         }
    //       ].map((project, index) => (
    //         <div 
    //           key={index}
    //           className="group relative bg-slate-800/50 rounded-lg p-6 border border-slate-700 hover:border-slate-600 transition-all duration-300"
    //         >
    //           <h3 className={`text-xl font-bold mb-3 bg-gradient-to-r ${project.color} bg-clip-text text-transparent`}>
    //             {project.title}
    //           </h3>
    //           <p className="text-slate-300 mb-4">{project.description}</p>
    //           <div className="flex gap-4 mb-4">
    //             {project.metrics.map(({ icon: Icon, text }, i) => (
    //               <div key={i} className="flex items-center gap-2 text-slate-400 text-sm">
    //                 <Icon size={16} />
    //                 <span>{text}</span>
    //               </div>
    //             ))}
    //           </div>
    //           <div className="flex flex-wrap gap-2">
    //             {project.tech.map((tech, i) => (
    //               <span 
    //                 key={i}
    //                 className="px-2 py-1 bg-slate-700/50 rounded text-sm text-slate-300"
    //               >
    //                 {tech}
    //               </span>
    //             ))}
    //           </div>
    //         </div>
    //       ))}
    //     </div>
    //   )
    // },
    contact: {
      icon: Mail,
      title: 'Contact',
      content: (
        <div className="space-y-6 animate-fadeIn">
          <div className="grid lg:grid-cols-2 sm:grid-cols-1 gap-4">
            {[
              { icon: Mail, text: 'imran.cuet.cse17@gmail.com', href: 'mailto:imran.cuet.cse17@gmail.com', label: 'Email', color: 'indigo' },
              { icon: Github, text: 'github.com/imranchowdhuryfahim', href: 'https://github.com/imranchowdhuryfahim', label: 'GitHub', color: 'purple' },
              { icon: Linkedin, text: 'linkedin.com/in/imran-chowdhury-fahim', href: 'https://linkedin.com/in/imran-chowdhury-fahim', label: 'LinkedIn', color: 'blue' },
              { icon: Globe, text: 'imranchowdhury.me', href: 'https://imranchowdhury.me', label: 'Website', color: 'green' }
            ].map(({ icon: Icon, text, href, label, color }) => (
              <a
                key={label}
                href={href}
                target="_blank"
                rel="noopener noreferrer"
                className={`bg-slate-800/50 p-4 rounded-lg border border-${color}-500/20 hover:border-${color}-500/40 transition-all group`}
              >
                <div className={`flex items-center gap-2 text-${color}-400 mb-2`}>
                  <Icon className={`group-hover:text-${color}-300 transition-colors`} size={20} />
                  <span className="font-semibold">{label}</span>
                </div>
                <div className="text-slate-300">{text}</div>
              </a>
            ))}
          </div>
        </div>
      )
    }
    
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-slate-950 via-slate-900 to-slate-950 ">
      <div className="flex h-screen">
        {/* Activity Bar */}
        <div className="w-16 bg-slate-900 border-r border-slate-800 flex flex-col items-center py-4 space-y-4">
          {Object.entries(sections).map(([key, { icon: Icon }]) => (
            <button
              key={key}
              onClick={() => setActiveSection(key)}
              className={`p-2 rounded-lg transition-all ${
                activeSection === key 
                  ? 'bg-indigo-500/20 text-indigo-400' 
                  : 'text-slate-400 hover:text-slate-300'
              }`}
            >
              <Icon size={24} />
            </button>
          ))}
        </div>

        {/* Main Content */}
        <div className="flex-1 flex flex-col">
          {/* Title Bar */}
          <div className="h-12 bg-slate-900 border-b border-slate-800 flex items-center px-4">
            <div className="flex items-center gap-2 text-slate-300">
              <span className="text-lg font-semibold">
                {sections[activeSection].title}
              </span>
            </div>
          </div>

          {/* Content Area */}
          <div className="flex-1 bg-slate-900 p-6 overflow-y-auto">
            {sections[activeSection].content}
          </div>

          {/* Terminal */}
          <div className="h-48 bg-slate-950 border-t border-slate-800">
            <div className="h-full flex flex-col">
              <div className="flex items-center gap-2 px-4 py-2 border-b border-slate-800 bg-slate-900">
                <Terminal size={16} className="text-slate-400" />
                <span className="text-slate-400 text-sm">Terminal</span>
              </div>
              <div className="flex-1 p-4 font-mono text-sm overflow-y-auto space-y-2">
                {terminalOutput.map((line, i) => (
                  <div 
                    key={i}
                    className={`${
                      line.type === 'info' ? 'text-blue-400' :
                      line.type === 'success' ? 'text-green-400' :
                      line.type === 'error' ? 'text-red-400' :
                      'text-slate-300'
                    }`}
                  >
                    {line.content}
                  </div>
                ))}
                <div className="flex items-center gap-2 text-slate-300">
                  <span className="text-green-400">❯</span>
                  <input
                    type="text"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    className="flex-1 bg-transparent outline-none"
                    placeholder={loading ? 'Initializing...' : 'Type a command...'}
                    disabled={loading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VSCodePortfolio;